import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
import { JobOrderApplicationFragmentFragmentDoc } from '../fragments/jobOrderApplication.fragment.generated';
const defaultOptions = {} as const;
export type ApplyForJobOrderMutationVariables = Types.Exact<{
  jobOrderId: Types.Scalars['String'];
  meetingDates: Array<Types.Scalars['String']> | Types.Scalars['String'];
  publicOfferId?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type ApplyForJobOrderMutation = {
  __typename?: 'Mutation';
  applyForJobOrder: {
    __typename?: 'JobOrderApplicationType';
    publicOfferId?: string | null;
    applicationId: string;
    createdAt: string;
    meetingDates: Array<string>;
    selectedMeetingDate?: string | null;
    status: Types.JobOrderApplicationStatusEnum;
    processStatus: Types.JobOrderApplicationProcessStatusEnum;
    meetingUrl?: string | null;
    isViewedByExpert: boolean;
    isHubApplicationDisabled: boolean;
    handler?: { __typename?: 'UserType'; name: string; email: string } | null;
  };
};

export const ApplyForJobOrderDocument = gql`
  mutation applyForJobOrder(
    $jobOrderId: String!
    $meetingDates: [String!]!
    $publicOfferId: String
  ) {
    applyForJobOrder(
      jobOrderId: $jobOrderId
      meetingDates: $meetingDates
      publicOfferId: $publicOfferId
    ) {
      ...JobOrderApplicationFragment
      publicOfferId
    }
  }
  ${JobOrderApplicationFragmentFragmentDoc}
`;
export type ApplyForJobOrderMutationFn = Apollo.MutationFunction<
  ApplyForJobOrderMutation,
  ApplyForJobOrderMutationVariables
>;

/**
 * __useApplyForJobOrderMutation__
 *
 * To run a mutation, you first call `useApplyForJobOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForJobOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForJobOrderMutation, { data, loading, error }] = useApplyForJobOrderMutation({
 *   variables: {
 *      jobOrderId: // value for 'jobOrderId'
 *      meetingDates: // value for 'meetingDates'
 *      publicOfferId: // value for 'publicOfferId'
 *   },
 * });
 */
export function useApplyForJobOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyForJobOrderMutation,
    ApplyForJobOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApplyForJobOrderMutation, ApplyForJobOrderMutationVariables>(
    ApplyForJobOrderDocument,
    options,
  );
}
export type ApplyForJobOrderMutationHookResult = ReturnType<typeof useApplyForJobOrderMutation>;
export type ApplyForJobOrderMutationResult = Apollo.MutationResult<ApplyForJobOrderMutation>;
export type ApplyForJobOrderMutationOptions = Apollo.BaseMutationOptions<
  ApplyForJobOrderMutation,
  ApplyForJobOrderMutationVariables
>;
