export const EnvironmentConfig = {
  apiUrl: process.env.REACT_APP_API_URL,
  webProfileUrl: process.env.REACT_APP_WEB_PROFILE_URL,
  englishLanguageId: process.env.REACT_APP_DEFAULT_LANGUAGE_ID ?? '',
  discordUrl: process.env.REACT_APP_DISCORD_URL ?? 'https://discord.com/',
  cognito: {
    linkedInCustomProviderName: process.env
      .REACT_APP_COGNITO_LINKED_IN_CUSTOM_PROVIDER_NAME as string,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
  },
  minimalScoreToApply: Number(process.env.REACT_APP_MINIMAL_SCORE_TO_APPLY) || 0,
  disableConsoleLog: process.env.REACT_APP_DISABLE_CONSOLE_LOG,
  googleRecaptchaKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY,
  googleAnalyticsTrackingId: process.env.REACT_APP_TRACKING_ID,
  googleAutocompleteApiKey: process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API,
  featureFlags: {
    prefillEmailDisabled: process.env.REACT_APP_FEATURE_FLAG_DISABLE_PREFILL,
    jobBoard: process.env.REACT_APP_FEATURE_FLAG_DISABLE_JOB_BOARD,
  },
  connectisWebsiteUrl: process.env.REACT_APP_CONNECTIS_WEBSITE_URL ?? '',
  socialLoginDisabled: process.env.REACT_APP_SOCIAL_LOGIN_DISABLED === 'true',
};
