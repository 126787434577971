import { useAsyncCallback, useBase64 } from 'hooks';
import { UploadImageIcon } from 'icons';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useUploadExpertPhotoMutation } from '../../../hooks/api/uploadExpertPhoto/uploadExpertAvatar.generated';
import { EditAvatarButton } from '../../Buttons';
import { LoadingSpinner } from '../../LoadingSpinner';

export const AVATAR_ALLOWED_MIME_TYPES = ['image/png', 'image/jpeg', 'image/webp'];

interface AvatarFileInputProps {
  name: string;
  expertId?: string;
  onUploadSuccess?: (fileUrl: string) => void;
  imageUrl?: string | null;
  maxFileSize?: number;
  maxFileResolution?: number;
}

const renderContent = (imageUrl: string | null, loading: boolean, restrictionsError: boolean) => {
  if (loading) return <LoadingSpinner />;
  if (imageUrl) {
    return (
      <>
        <EditAvatarButton />
        <img alt="" className="w-full h-full" src={imageUrl} />
      </>
    );
  }
  return <UploadImageIcon className="text-gray-400 w-12 h-12" />;
};

export const AvatarFileInput: FC<
  AvatarFileInputProps & { onRestrictionsErrorChange: (error: boolean) => void }
> = ({
  name,
  expertId,
  onUploadSuccess,
  imageUrl,
  maxFileSize,
  maxFileResolution,
  onRestrictionsErrorChange,
}) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [restrictionsError, setRestrictionsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getBase64FromFile } = useBase64();

  const [uploadExpertPhotoMutation] = useUploadExpertPhotoMutation();
  const uploadPhotoFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!expertId) return;

    const [file] = e.target.files ?? [];
    if (!file) return;

    setLoading(true);

    if (maxFileSize && file.size > maxFileSize) {
      setRestrictionsError(true);
      onRestrictionsErrorChange(true);
      setLoading(false);
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = async () => {
      if (
        (maxFileResolution && img.width > maxFileResolution) ||
        (maxFileResolution && img.height > maxFileResolution)
      ) {
        setLoading(false);
        setRestrictionsError(true);
        onRestrictionsErrorChange(true);
        return;
      }
      const fileBase64 = await getBase64FromFile(file);
      const res = await uploadExpertPhotoMutation({
        variables: {
          expertId,
          params: {
            fileBase64,
            mimeType: file.type,
          },
        },
      });
      const { uploadExpertPhoto: uploadedFileUrl } = res.data ?? {};
      if (uploadedFileUrl && onUploadSuccess && imageUrl) {
        onUploadSuccess(imageUrl);
      }
      if (uploadedFileUrl) {
        setRestrictionsError(false);
        onRestrictionsErrorChange(false);
        setFileUrl(uploadedFileUrl);
        onUploadSuccess?.(uploadedFileUrl);
      }
      setLoading(false);
    };
  };

  const [handleFileUpload] = useAsyncCallback(uploadPhotoFile);

  useEffect(() => {
    if (!imageUrl || fileUrl) return;
    setFileUrl(imageUrl);
  }, [imageUrl]);

  return (
    <label
      className="border border-gray-200 bg-gray-100 border-dashed rounded-full w-[100px] md:w-[160px] h-[100px] md:h-[160px] flex items-center justify-center overflow-hidden relative group cursor-pointer"
      htmlFor={name}
    >
      {renderContent(fileUrl, loading, restrictionsError)}
      <input
        accept={AVATAR_ALLOWED_MIME_TYPES.join(', ')}
        className="opacity-0 absolute w-full h-full cursor-pointer"
        id={name}
        name={name}
        onChange={handleFileUpload}
        type="file"
      />
    </label>
  );
};
