export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export enum LanguageLevelEnum {
  None = 'None',
  Starter = 'Starter',
  Elementary = 'Elementary',
  PreIntermediate = 'PreIntermediate',
  Intermediate = 'Intermediate',
  UpperIntermediate = 'UpperIntermediate',
  Advanced = 'Advanced',
}

export enum TagTypeEnum {
  Default = 'Default',
  TechnicalSkill = 'TechnicalSkill',
}

export enum WorkModeEnum {
  Office = 'Office',
  Remote = 'Remote',
  Hybrid = 'Hybrid',
}

export enum SeniorityLevelEnum {
  Junior = 'Junior',
  Regular = 'Regular',
  Senior = 'Senior',
}

export enum ExpertAvailabilityEnum {
  Immediately = 'Immediately',
  Days = 'Days',
  Months = 'Months',
}

export enum ExpertAvatarEnum {
  Photo = 'Photo',
  WordCloud = 'WordCloud',
}

export enum ExpertDataSourceEnum {
  Crm = 'CRM',
  Hub = 'Hub',
  Prospect = 'Prospect',
}

export enum ExpertDataContextEnum {
  Internal = 'Internal',
  Hub = 'Hub',
  AiExtracted = 'AIExtracted',
}

export enum ExpertBlindFlowStatusEnum {
  NotSent = 'NotSent',
  Sent = 'Sent',
  MeetingsScheduled = 'MeetingsScheduled',
  MeetingsCancelled = 'MeetingsCancelled',
  CiCancelledByExpert = 'CICancelledByExpert',
  CiCancelledByClient = 'CICancelledByClient',
  CiScheduled = 'CIScheduled',
  CiFinished = 'CIFinished',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  RejectedAfterCi = 'RejectedAfterCI',
  RejectedOffer = 'RejectedOffer',
  NoFeedback = 'NoFeedback',
  ExpertWithdrew = 'ExpertWithdrew',
  ClientWithdrew = 'ClientWithdrew',
  Placement = 'Placement',
  CiScheduledSecondStage = 'CIScheduledSecondStage',
  CiFinishedSecondStage = 'CIFinishedSecondStage',
  CiScheduledThirdStage = 'CIScheduledThirdStage',
  CiFinishedThirdStage = 'CIFinishedThirdStage',
  RejectedBySales = 'RejectedBySales',
  RejectedByExpert = 'RejectedByExpert',
}

export enum ExpertBlindFlowStatusFeedbackEnum {
  RateMismatch = 'RateMismatch',
  LocationMismatch = 'LocationMismatch',
  NoticePeriodMismatch = 'NoticePeriodMismatch',
  CandidatePersonalityMismatch = 'CandidatePersonalityMismatch',
  ProjectDidNotMeetWorkModeExpectations = 'ProjectDidNotMeetWorkModeExpectations',
  ProjectDidNotMeetOrganizationalExpectations = 'ProjectDidNotMeetOrganizationalExpectations',
  ProjectDidNotMeetFinancialExpectations = 'ProjectDidNotMeetFinancialExpectations',
  ProjectDidNotMeetTechnicalExpectations = 'ProjectDidNotMeetTechnicalExpectations',
  TechnicallyIncompetentCandidate = 'TechnicallyIncompetentCandidate',
  RequirementsChanged = 'RequirementsChanged',
  ExpectationsChanged = 'ExpectationsChanged',
  ChoosingOtherCandidate = 'ChoosingOtherCandidate',
  NoInformation = 'NoInformation',
  ProcessedDirectlyOrByAnotherVendor = 'ProcessedDirectlyOrByAnotherVendor',
  ClientMadeBadImpression = 'ClientMadeBadImpression',
  Counteroffer = 'Counteroffer',
  BrokenContact = 'BrokenContact',
  TooLongRecruitmentProcess = 'TooLongRecruitmentProcess',
  OurContract = 'OurContract',
  CandidateRemainsWithCurrentCompany = 'CandidateRemainsWithCurrentCompany',
  Formalities = 'Formalities',
  BudgetChanged = 'BudgetChanged',
  AlreadyMetWithClient = 'AlreadyMetWithClient',
  Selection = 'Selection',
  ClientRejected = 'ClientRejected',
}

export enum MatchingScoreWeightingStrategyEnum {
  Default = 'Default',
  ExpertBlind = 'ExpertBlind',
  ExpertBlindWithRolesStrategy = 'ExpertBlindWithRolesStrategy',
  RolesIncludedStrategy = 'RolesIncludedStrategy',
}

export enum ProfileSourceEnum {
  Crm = 'CRM',
  Hub = 'Hub',
}

export enum ExpertBlindTypeEnum {
  Standard = 'Standard',
  Attrition = 'Attrition',
  RoleBased = 'RoleBased',
}

export enum UserRole {
  SuperAdmin = 'SuperAdmin',
  Backoffice = 'Backoffice',
  Planner = 'Planner',
  Sales = 'Sales',
  Delivery = 'Delivery',
  Extractor = 'Extractor',
  SuperSales = 'SuperSales',
}

export enum DifficultyLevelEnum {
  Hard = 'Hard',
  Medium = 'Medium',
  Easy = 'Easy',
}

export enum QualityLevelEnum {
  VeryGood = 'VeryGood',
  Good = 'Good',
  Bad = 'Bad',
  NoDescription = 'NoDescription',
}

export enum FeedbackScoreEnum {
  OneTwoDays = 'OneTwoDays',
  ThreeFourDays = 'ThreeFourDays',
  MorThanFiveDays = 'MorThanFiveDays',
}

export enum RecruitmentProcessLengthEnum {
  OneStage = 'OneStage',
  TwoStages = 'TwoStages',
  MoreThanTwoStages = 'MoreThanTwoStages',
}

export enum RecruitmentTestEnum {
  NoTest = 'NoTest',
  Test = 'Test',
  Assigment = 'Assigment',
}

export enum ContractSignedEnum {
  Yes = 'Yes',
  Processing = 'Processing',
  No = 'No',
}

export enum JobOrderStatusTypeEnum {
  BusinessStatus = 'businessStatus',
  DefinitionOfReadyStatus = 'definitionOfReadyStatus',
}

export enum JobOrderStatusMergeEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  ToFill = 'ToFill',
  SubmittedForEvaluation = 'SubmittedForEvaluation',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export enum JobOrderResultEnum {
  Winning = 'Winning',
  Lost = 'Lost',
}

export enum JobOrderBusinessStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum JobOrderDefinitionOfReadyStatusEnum {
  ToFill = 'ToFill',
  SubmittedForEvaluation = 'SubmittedForEvaluation',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export enum WorkMethodologyEnum {
  Scrum = 'Scrum',
  Agile = 'Agile',
  Kanban = 'Kanban',
  Waterfall = 'Waterfall',
  Lean = 'Lean',
  SixSigma = 'SixSigma',
  Pmbok = 'PMBOK',
  Cpm = 'CPM',
  Ccpm = 'CCPM',
  Prince2 = 'Prince2',
  Other = 'Other',
}

export enum WorkOrderLengthEnum {
  UpToThreeMonths = 'UpToThreeMonths',
  UpToSixMonths = 'UpToSixMonths',
  MoreThanSevenMonths = 'MoreThanSevenMonths',
}

export enum MaxAvailabilityEnum {
  Asap = 'ASAP',
  Month = 'Month',
  MoreThanTwoMonths = 'MoreThanTwoMonths',
}

export enum WorkHourScopeEnum {
  Elastic = 'Elastic',
  Standard = 'Standard',
  Diffrent = 'Diffrent',
}

export enum FillRateKpiCommentEnum {
  Hunting = 'Hunting',
  FarmersHunting = 'FarmersHunting',
  Deadline = 'Deadline',
  Other = 'Other',
}

export enum JobOrderTypeEnum {
  Hunting = 'Hunting',
  Farming = 'Farming',
}

export enum ExpertCvSourceEnum {
  Crm = 'CRM',
  External = 'External',
  Hub = 'Hub',
  Matcher = 'Matcher',
}

export enum ExpertCvLocationEnum {
  Dynamics = 'Dynamics',
  Local = 'Local',
}

export enum JobOrderApplicationStatusEnum {
  WaitingForConfirmation = 'WaitingForConfirmation',
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
}

export enum JobOrderApplicationProcessStatusEnum {
  Applied = 'Applied',
  ScheduledForCq = 'ScheduledForCQ',
  AcceptedAfterCq = 'AcceptedAfterCQ',
  RejectedAfterApplied = 'RejectedAfterApplied',
  RejectedAfterCq = 'RejectedAfterCQ',
  NotSent = 'NotSent',
  Sent = 'Sent',
  MeetingsScheduled = 'MeetingsScheduled',
  MeetingsCancelled = 'MeetingsCancelled',
  CiCancelledByExpert = 'CICancelledByExpert',
  CiCancelledByClient = 'CICancelledByClient',
  CiScheduled = 'CIScheduled',
  CiFinished = 'CIFinished',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  RejectedAfterCi = 'RejectedAfterCI',
  RejectedOffer = 'RejectedOffer',
  NoFeedback = 'NoFeedback',
  ExpertWithdrew = 'ExpertWithdrew',
  ClientWithdrew = 'ClientWithdrew',
  Placement = 'Placement',
  CiScheduledSecondStage = 'CIScheduledSecondStage',
  CiFinishedSecondStage = 'CIFinishedSecondStage',
  CiScheduledThirdStage = 'CIScheduledThirdStage',
  CiFinishedThirdStage = 'CIFinishedThirdStage',
  RejectedBySales = 'RejectedBySales',
  OfferExpired = 'OfferExpired',
  RejectedByExpert = 'RejectedByExpert',
}

export enum JobOrderApplicationStatusFeedbackEnum {
  RateMismatch = 'RateMismatch',
  LocationMismatch = 'LocationMismatch',
  NoticePeriodMismatch = 'NoticePeriodMismatch',
  CandidatePersonalityMismatch = 'CandidatePersonalityMismatch',
  ProjectDidNotMeetWorkModeExpectations = 'ProjectDidNotMeetWorkModeExpectations',
  ProjectDidNotMeetOrganizationalExpectations = 'ProjectDidNotMeetOrganizationalExpectations',
  ProjectDidNotMeetFinancialExpectations = 'ProjectDidNotMeetFinancialExpectations',
  ProjectDidNotMeetTechnicalExpectations = 'ProjectDidNotMeetTechnicalExpectations',
  TechnicallyIncompetentCandidate = 'TechnicallyIncompetentCandidate',
  RequirementsChanged = 'RequirementsChanged',
  ExpectationsChanged = 'ExpectationsChanged',
  ChoosingOtherCandidate = 'ChoosingOtherCandidate',
  NoInformation = 'NoInformation',
  ProcessedDirectlyOrByAnotherVendor = 'ProcessedDirectlyOrByAnotherVendor',
  ClientMadeBadImpression = 'ClientMadeBadImpression',
  Counteroffer = 'Counteroffer',
  BrokenContact = 'BrokenContact',
  TooLongRecruitmentProcess = 'TooLongRecruitmentProcess',
  OurContract = 'OurContract',
  CandidateRemainsWithCurrentCompany = 'CandidateRemainsWithCurrentCompany',
  Formalities = 'Formalities',
  BudgetChanged = 'BudgetChanged',
  AlreadyMetWithClient = 'AlreadyMetWithClient',
  Selection = 'Selection',
  ClientRejected = 'ClientRejected',
}

export enum JobOrderApplicationCreationMethodEnum {
  Hub = 'Hub',
  ExpertBlind = 'ExpertBlind',
  PublicOffer = 'PublicOffer',
}

export enum ExpertNotificationTypeEnum {
  JobOrderMatched = 'JobOrderMatched',
  JobOrderApplicationMeetingScheduled = 'JobOrderApplicationMeetingScheduled',
  JobOrderApplicationStatusChange = 'JobOrderApplicationStatusChange',
  JobOrderApplicationCreated = 'JobOrderApplicationCreated',
}

export enum ExpertIdentitySourceEnum {
  Cognito = 'Cognito',
  Google = 'Google',
  LinkedIn = 'LinkedIn',
}

export enum PublicOfferStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
}

export enum PublicOfferContextEnum {
  Marketing = 'Marketing',
  JustJoinIt = 'JustJoinIT',
  JobBoard = 'JobBoard',
  NoFluffJobs = 'NoFluffJobs',
  LinkedIn = 'LinkedIn',
  Other = 'Other',
  Process = 'Process',
  Referral = 'Referral',
  ConnectisJobBoard = 'ConnectisJobBoard',
  PracujPl = 'PracujPL',
  Inhire = 'Inhire',
}

export enum ExpertCvExtractionJobStatusEnum {
  Created = 'Created',
  AnonymizationInProgress = 'AnonymizationInProgress',
  AnonymizationCompleted = 'AnonymizationCompleted',
  AnonymizationFailed = 'AnonymizationFailed',
  ExtractionInProgress = 'ExtractionInProgress',
  ExtractionCompleted = 'ExtractionCompleted',
  ExtractionFailed = 'ExtractionFailed',
  Canceled = 'Canceled',
}

export enum RecommenderJobStatusEnum {
  Requested = 'Requested',
  Failed = 'Failed',
  Completed = 'Completed',
}

export enum ExpertRolesExtractionJobStatusEnum {
  Requested = 'Requested',
  Failed = 'Failed',
  Completed = 'Completed',
}

export enum RankingerJobStatusEnum {
  Requested = 'Requested',
  Failed = 'Failed',
  Completed = 'Completed',
}

export enum PublicOfferEventTypeEnum {
  Open = 'Open',
  Apply = 'Apply',
  Login = 'Login',
  JobOrderApplication = 'JobOrderApplication',
}

export enum JobOrderDescriptorJobStatusEnum {
  Requested = 'Requested',
  Failed = 'Failed',
  Completed = 'Completed',
}

export enum ExpertHubExtractionStateEnum {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Error = 'Error',
}

export enum OnboardingModeEnum {
  Manual = 'Manual',
  Auto = 'Auto',
}

export enum ExpertHubSourceEnum {
  External = 'External',
  Organic = 'Organic',
}

export type ExpertCertificateInputType = {
  name: Scalars['String'];
  issuer?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  englishDescription?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  issueDate?: InputMaybe<Scalars['DateTime']>;
};

export type ExpertWorkHistoryInputType = {
  jobPositionName?: InputMaybe<Scalars['String']>;
  englishJobPositionName?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  englishIndustry?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  dateStart: Scalars['DateTime'];
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  isCurrentJob?: InputMaybe<Scalars['Boolean']>;
  isCompanyNameSkipped?: InputMaybe<Scalars['Boolean']>;
  tagsIds?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  englishDescription?: InputMaybe<Scalars['String']>;
};

export type ExpertNotificationConsentsInputType = {
  OffersAndProfiles: Scalars['Boolean'];
  ApplicationAndMeetings: Scalars['Boolean'];
  News: Scalars['Boolean'];
};

export type TermInput = {
  offset: Scalars['Int'];
  value: Scalars['String'];
};

export type MatchedSubstringInput = {
  offset: Scalars['Int'];
  length: Scalars['Int'];
};

export type StructuredFormattingInput = {
  main_text: Scalars['String'];
  secondary_text: Scalars['String'];
  main_text_matched_substrings: Array<MatchedSubstringInput>;
  secondary_text_matched_substrings?: InputMaybe<Array<MatchedSubstringInput>>;
};

export type GoogleLocationObjectInput = {
  description: Scalars['String'];
  matched_substrings: Array<MatchedSubstringInput>;
  place_id: Scalars['String'];
  reference: Scalars['String'];
  structured_formatting: StructuredFormattingInput;
  terms: Array<TermInput>;
  types: Array<Scalars['String']>;
};

export type JobOrderAssigneesParamsInputType = {
  brm?: InputMaybe<Scalars['String']>;
  talentAdvocates?: InputMaybe<Array<Scalars['String']>>;
};

export type JobOrderStatusHistoryInputType = {
  statusType: JobOrderStatusTypeEnum;
  changedFrom?: InputMaybe<JobOrderStatusMergeEnum>;
  changedTo: JobOrderStatusMergeEnum;
  changeDate: Scalars['DateTime'];
  changedById: Scalars['String'];
  resultStatus?: InputMaybe<JobOrderResultEnum>;
  comments?: InputMaybe<Scalars['String']>;
  isReverted: Scalars['Boolean'];
};

export type JobOrderBusinessOwnerInputType = {
  id: Scalars['String'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type JobOrderAssignmentHistoryInputType = {
  assignmentDate: Scalars['DateTime'];
  changedById: Scalars['String'];
  owner: Scalars['String'];
  assignees: Array<JobOrderAssigneesParamsInputType>;
  isBttlReset: Scalars['Boolean'];
  isReverted: Scalars['Boolean'];
};

export type JobOrderCustomFillRateInputType = {
  customTargetPTC?: InputMaybe<Scalars['Int']>;
  customDeadlinePTC?: InputMaybe<Scalars['DateTime']>;
  customTargetCI?: InputMaybe<Scalars['Int']>;
  customDeadlineCI?: InputMaybe<Scalars['DateTime']>;
  customTargetPlacement?: InputMaybe<Scalars['Int']>;
  customDeadlinePlacement?: InputMaybe<Scalars['DateTime']>;
};

export type JobOrderCustomFillrateHistoryInputType = {
  customTargetPTC?: InputMaybe<Scalars['Int']>;
  customDeadlinePTC?: InputMaybe<Scalars['DateTime']>;
  customTargetCI?: InputMaybe<Scalars['Int']>;
  customDeadlineCI?: InputMaybe<Scalars['DateTime']>;
  customTargetPlacement?: InputMaybe<Scalars['Int']>;
  customDeadlinePlacement?: InputMaybe<Scalars['DateTime']>;
  changedById?: InputMaybe<Scalars['String']>;
  changedAt: Scalars['DateTime'];
};

export type MainTechnologyInputType = {
  tagId: Scalars['String'];
  defaultTagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type JobOrderPublicationMediumInputType = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ExpertBlindPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type BlindsFiltersInputType = {
  jobOrderCustomId?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  createdByName?: InputMaybe<Scalars['String']>;
  crmId?: InputMaybe<Scalars['String']>;
  expertEmploymentStatus?: InputMaybe<Scalars['String']>;
  expertName?: InputMaybe<Scalars['String']>;
  flowStatus?: InputMaybe<Array<ExpertBlindFlowStatusEnum>>;
  jobOrderName?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  profileSource?: InputMaybe<Array<ProfileSourceEnum>>;
  businessOwner?: InputMaybe<Scalars['String']>;
  dataSource?: InputMaybe<Array<ExpertDataSourceEnum>>;
  companyContactHubSpotId?: InputMaybe<Scalars['String']>;
  verifiedAt?: InputMaybe<DateRangeType>;
};

export type DateRangeType = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type BlindsSortInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export type MatchJobOrderToExpertWithCustomSkillsInputType = {
  jobOrderId: Scalars['String'];
  expertId: Scalars['String'];
  blindTags?: InputMaybe<Array<ExpertTagWeightInputType>>;
};

export type ExpertTagWeightInputType = {
  tagId: Scalars['String'];
  weight: Scalars['Int'];
};

export type FindManyJobOrdersPaginationInput = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type JobOrdersFiltersInputType = {
  customId?: InputMaybe<Scalars['Int']>;
  experienceYears?: InputMaybe<NullableIntRangeType>;
  rate?: InputMaybe<NullableFloatRangeType>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  businessOwner?: InputMaybe<Scalars['String']>;
  businessStatus?: InputMaybe<JobOrderBusinessStatusEnum>;
  definitionOfReadyStatus?: InputMaybe<Array<JobOrderDefinitionOfReadyStatusEnum>>;
  skillsMustHave?: InputMaybe<Array<Scalars['String']>>;
  skillsNiceToHave?: InputMaybe<Array<Scalars['String']>>;
  createdByEmail?: InputMaybe<Array<Scalars['String']>>;
  assignee?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Array<Scalars['String']>>;
  isAssigned?: InputMaybe<Scalars['Boolean']>;
  fillRateKpi?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
  roleName?: InputMaybe<Array<Scalars['String']>>;
  leadingTechnologies?: InputMaybe<Array<Scalars['String']>>;
  seniority?: InputMaybe<Array<SeniorityLevelEnum>>;
  workMode?: InputMaybe<Array<WorkModeEnum>>;
  locationPreference?: InputMaybe<Array<Scalars['String']>>;
  minEnglishLevel?: InputMaybe<Array<LanguageLevelEnum>>;
  dateStart?: InputMaybe<DateRangeType>;
  assignmentDate?: InputMaybe<DateRangeType>;
  createdAt?: InputMaybe<DateRangeType>;
  submittedForEvaluationDate?: InputMaybe<DateRangeType>;
  acceptanceDate?: InputMaybe<DateRangeType>;
  isChubVisible?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<JobOrderTypeEnum>;
  publications?: InputMaybe<Array<JobOrderPublicationMediumEnum>>;
  finalScore?: InputMaybe<NullableIntRangeType>;
  summarySentAt?: InputMaybe<DateRangeType>;
  bttl?: InputMaybe<NullableIntRangeType>;
  resultStatus?: InputMaybe<JobOrderResultEnum>;
};

export type NullableIntRangeType = {
  min?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['Int']>;
};

export type NullableFloatRangeType = {
  min?: InputMaybe<Scalars['Float']>;
  max?: InputMaybe<Scalars['Float']>;
};

export enum JobOrderPublicationMediumEnum {
  ConnectisWww = 'CONNECTIS_WWW',
  JustJoinIt = 'JUST_JOIN_IT',
  NoFluffJobs = 'NO_FLUFF_JOBS',
  Jobslot = 'JOBSLOT',
  PracujPl = 'PRACUJ_PL',
}

export type FindManyJobOrdersSortByInputType = {
  field: FindManyJobOrderSortByEnum;
  sort?: InputMaybe<FindManySortTypeEnum>;
};

export enum FindManyJobOrderSortByEnum {
  ClientName = 'ClientName',
  CustomId = 'CustomId',
  Name = 'Name',
  Role = 'Role',
  Rate = 'Rate',
  Availability = 'Availability',
  CreatedAt = 'CreatedAt',
  BusinessOwner = 'BusinessOwner',
  BusinessStatus = 'BusinessStatus',
  Seniority = 'Seniority',
  WorkMode = 'WorkMode',
  MinEnglishLevel = 'MinEnglishLevel',
  SubmittedForEvaluationDate = 'SubmittedForEvaluationDate',
  AcceptanceDate = 'AcceptanceDate',
  AssignmentDate = 'AssignmentDate',
  DateStart1 = 'DateStart1',
  Bttl = 'Bttl',
  JobOrderScore = 'JobOrderScore',
  FinalScore = 'FinalScore',
  ExperienceYears = 'ExperienceYears',
  RateRangeTo = 'RateRangeTo',
  Vacancies = 'Vacancies',
  CreatedByEmail = 'CreatedByEmail',
  Owner = 'Owner',
  DefinitionOfReadyStatus = 'DefinitionOfReadyStatus',
}

export enum FindManySortTypeEnum {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type MatchJobOrdersSortByInputType = {
  field: MatchJobOrdersSortByEnum;
  sort?: InputMaybe<FindManySortTypeEnum>;
};

export enum MatchJobOrdersSortByEnum {
  MustHaveSkillsScore = 'MustHaveSkillsScore',
  NiceToHaveSkillsScore = 'NiceToHaveSkillsScore',
  SalaryScore = 'SalaryScore',
  WorkModeScore = 'WorkModeScore',
  EnglishLevelScore = 'EnglishLevelScore',
  AvailabilityScore = 'AvailabilityScore',
  FinalScore = 'FinalScore',
  MinimalSkillLevel = 'MinimalSkillLevel',
}

export type TagsQueryFiltersType = {
  type?: InputMaybe<TagTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type OtherJobOrdersQueryInputType = {
  sortBy?: InputMaybe<JobOrdersQuerySortByEnum>;
  limit?: InputMaybe<Scalars['Int']>;
};

export enum JobOrdersQuerySortByEnum {
  BestMatch = 'bestMatch',
  CreatedAtDesc = 'createdAtDesc',
  CreatedAtAsc = 'createdAtAsc',
  AssignmentDateDesc = 'assignmentDateDesc',
  AssignmentDateAsc = 'assignmentDateAsc',
  RateDesc = 'rateDesc',
  RateAsc = 'rateAsc',
}

export type JobOrderApplicationPaginationInput = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type CandidatesFiltersInputType = {
  name?: InputMaybe<Scalars['String']>;
  handlingConfirmedAt?: InputMaybe<DateRangeType>;
  handlingExpiresAt?: InputMaybe<DateRangeType>;
  createdAt?: InputMaybe<DateRangeType>;
  customId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<JobOrderApplicationStatusEnum>>;
  processStatus?: InputMaybe<Array<JobOrderApplicationProcessStatusEnum>>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  handlerId?: InputMaybe<Scalars['String']>;
  jobOrderId?: InputMaybe<Scalars['String']>;
  isForSingleUser?: InputMaybe<Scalars['Boolean']>;
  expertId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export enum ExpertNotificationSortEnum {
  UnviewedFirst = 'UnviewedFirst',
  CreatedAtDesc = 'CreatedAtDesc',
  CreatedAtAsc = 'CreatedAtAsc',
}

export type PublicOffersFiltersInputType = {
  jobOrderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PublicOfferStatusEnum>;
  crmId?: InputMaybe<Scalars['Int']>;
  client?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  businessStatus?: InputMaybe<Array<JobOrderBusinessStatusEnum>>;
  definitionOfReadyStatus?: InputMaybe<Array<JobOrderDefinitionOfReadyStatusEnum>>;
  ownerIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateRangeType>;
  context?: InputMaybe<PublicOfferContextEnum>;
  contextContactEmail?: InputMaybe<Scalars['String']>;
  sentTo?: InputMaybe<Scalars['String']>;
};

export type PublicOfferSortInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export type PublicOfferApplicationsFiltersInputType = {
  offerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PublicOfferApplicationStatusEnum>;
  crmId?: InputMaybe<Scalars['Int']>;
  expert?: InputMaybe<Scalars['String']>;
  expertId?: InputMaybe<Scalars['String']>;
  jobOrderCustomId?: InputMaybe<Scalars['Int']>;
  ownerIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateRangeType>;
  isOnboardingFinished?: InputMaybe<Scalars['Boolean']>;
  dataSource?: InputMaybe<Array<ExpertDataSourceEnum>>;
  email?: InputMaybe<Scalars['String']>;
};

export enum PublicOfferApplicationStatusEnum {
  New = 'New',
}

export type PublicOfferApplicationsSortInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export enum StatisticsCounterEnum {
  SignedUp = 'SignedUp',
  SignedUpWithFinishedOnboarding = 'SignedUpWithFinishedOnboarding',
  MatchingEligibleExperts = 'MatchingEligibleExperts',
  ExpertsWithCreatedBlind = 'ExpertsWithCreatedBlind',
  ExpertsFilledWithAi = 'ExpertsFilledWithAI',
  OnlyProspects = 'OnlyProspects',
}

export type MatchExpertsToJobOrderInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export type ExpertJobOrderFiltersInputType = {
  name?: InputMaybe<Scalars['String']>;
  locationPreference?: InputMaybe<Array<Scalars['String']>>;
  crmId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<DateRangeType>;
  crmCreatedAt?: InputMaybe<DateRangeType>;
  hubCreatedAt?: InputMaybe<DateRangeType>;
  lastNoteCreatedAt?: InputMaybe<DateRangeType>;
  businessStatus?: InputMaybe<JobOrderBusinessStatusEnum>;
  finalScore?: InputMaybe<NullableIntRangeType>;
  rate?: InputMaybe<NullableFloatRangeType>;
  email?: InputMaybe<Scalars['String']>;
  skillsMustHave?: InputMaybe<Array<Scalars['String']>>;
  englishLevel?: InputMaybe<Array<Scalars['String']>>;
  availability?: InputMaybe<Array<Scalars['String']>>;
  skillsNiceToHave?: InputMaybe<Array<Scalars['String']>>;
  leadingTechnologies?: InputMaybe<Array<Scalars['String']>>;
  roleName?: InputMaybe<Array<Scalars['String']>>;
  workType?: InputMaybe<Array<Scalars['String']>>;
  dataSource?: InputMaybe<Array<ExpertDataSourceEnum>>;
  seniority?: InputMaybe<Array<SeniorityLevelEnum>>;
};

export type ExpertsPaginationInput = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type ExpertFinderFiltersInputType = {
  crmId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  leadingTechnology?: InputMaybe<Array<Scalars['String']>>;
  skills?: InputMaybe<Array<Scalars['String']>>;
  workType?: InputMaybe<Array<WorkModeEnum>>;
  location?: InputMaybe<Array<Scalars['String']>>;
  rateMin?: InputMaybe<Scalars['Int']>;
  rateMax?: InputMaybe<Scalars['Int']>;
  availability?: InputMaybe<Array<ExpertAvailabilityEnum>>;
  englishLevel?: InputMaybe<Array<LanguageLevelEnum>>;
  crmCreatedAt?: InputMaybe<Array<Scalars['DateTime']>>;
  hubCreatedAt?: InputMaybe<Array<Scalars['DateTime']>>;
  lastNoteCreatedAt?: InputMaybe<Array<Scalars['DateTime']>>;
  dataSource?: InputMaybe<Array<ExpertDataSourceEnum>>;
  roleName?: InputMaybe<Array<Scalars['String']>>;
  seniority?: InputMaybe<Array<SeniorityLevelEnum>>;
};

export type ExpertFinderSortInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export type PaginationType = {
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type ExpertCvExtractionJobsFiltersInputType = {
  expertId?: InputMaybe<Scalars['String']>;
  expert?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeType>;
  status?: InputMaybe<Array<ExpertCvExtractionJobStatusEnum>>;
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PublicJobBoardOffersFiltersInputType = {
  workMode?: InputMaybe<Array<WorkModeEnum>>;
  seniority?: InputMaybe<Array<SeniorityLevelEnum>>;
  rateRangeFrom?: InputMaybe<Scalars['Int']>;
  rateRangeTo?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
};

export type RankingerFiltersInputType = {
  id?: InputMaybe<Scalars['String']>;
  jobOrderCustomId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<RankingerJobStatusEnum>>;
  createdById?: InputMaybe<Array<Scalars['String']>>;
  completedAt?: InputMaybe<DateRangeType>;
};

export enum CompaniesSortEnum {
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
  ContactsCountAsc = 'ContactsCountAsc',
  ContactsCountDesc = 'ContactsCountDesc',
  DashboardsCountAsc = 'DashboardsCountAsc',
  DashboardsCountDesc = 'DashboardsCountDesc',
  JobOrdersCountAsc = 'JobOrdersCountAsc',
  JobOrdersCountDesc = 'JobOrdersCountDesc',
  AcceptedJobOrdersCountAsc = 'AcceptedJobOrdersCountAsc',
  AcceptedJobOrdersCountDesc = 'AcceptedJobOrdersCountDesc',
  ActiveJobOrdersCountAsc = 'ActiveJobOrdersCountAsc',
  ActiveJobOrdersCountDesc = 'ActiveJobOrdersCountDesc',
  JobOrdersInPlanningCountAsc = 'JobOrdersInPlanningCountAsc',
  JobOrdersInPlanningCountDesc = 'JobOrdersInPlanningCountDesc',
}

export type CompaniesQueryFiltersInputType = {
  searchPhrase?: InputMaybe<Scalars['String']>;
  hubSpotOwner?: InputMaybe<Scalars['String']>;
  assignedHubSpotOwner?: InputMaybe<Scalars['String']>;
};

export type ClientsDashboardsPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ClientsDashboardsFiltersInputType = {
  clientName?: InputMaybe<Scalars['String']>;
  companyContact?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeType>;
  hubSpotOwner?: InputMaybe<Scalars['String']>;
  assignedHubSpotOwner?: InputMaybe<Scalars['String']>;
};

export type ClientsDashboardsSortInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export type SendBlindNotificationEmailInputType = {
  blindIds: Array<Scalars['String']>;
  text: Scalars['String'];
  accessToken: Scalars['String'];
  to: Array<Scalars['String']>;
  cc?: InputMaybe<Array<Scalars['String']>>;
  attachments?: InputMaybe<Array<SendBlindAttachmentType>>;
  subject?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  isPdfAttached: Scalars['Boolean'];
  alreadyUploadedAttachmentIds: Array<Scalars['String']>;
};

export type SendBlindAttachmentType = {
  contentBase64: Scalars['String'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
};

export type InsertJobPositionInputType = {
  name: Scalars['String'];
  defaultTechnologies?: InputMaybe<Array<Scalars['String']>>;
  availableMainTechnologies?: InputMaybe<Array<JobPositionMainTechnologiesInputType>>;
  isOnMainList?: InputMaybe<Scalars['Boolean']>;
};

export type JobPositionMainTechnologiesInputType = {
  name?: InputMaybe<Scalars['String']>;
  mainTechnologies: Array<MainTechnologyInputType>;
};

export type UpdateJobPositionInputType = {
  name?: InputMaybe<Scalars['String']>;
  defaultTechnologies?: InputMaybe<Array<Scalars['String']>>;
  availableMainTechnologies?: InputMaybe<Array<JobPositionMainTechnologiesInputType>>;
  isOnMainList?: InputMaybe<Scalars['Boolean']>;
};

export type CreateBlindInputType = {
  version: Scalars['Int'];
  availability: Scalars['String'];
  jobOrderId: Scalars['String'];
  contactPersonId: Scalars['String'];
  recommendation?: InputMaybe<Scalars['String']>;
  englishRecommendation?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  margin: Scalars['Float'];
  isMoneyRateDisplayed: Scalars['Boolean'];
  isRecommendationDisplayed: Scalars['Boolean'];
  isWorkHistoryDisplayed: Scalars['Boolean'];
  isCertificatesDisplayed: Scalars['Boolean'];
  isBlindMinus: Scalars['Boolean'];
  isBlindPlus: Scalars['Boolean'];
  isVendorless: Scalars['Boolean'];
  isDisplayOnlyFirstLetterOfLastNameChosen: Scalars['Boolean'];
  isDatepickerHidden: Scalars['Boolean'];
  isFullProfile: Scalars['Boolean'];
  isMoneyRateHidden: Scalars['Boolean'];
  isLastNameHidden: Scalars['Boolean'];
  areCompanyNamesHidden: Scalars['Boolean'];
  isGradeDisabled: Scalars['Boolean'];
  createJobOrderApplication?: InputMaybe<Scalars['Boolean']>;
  profileSource: ProfileSourceEnum;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  tagsVisibleOnBlind?: InputMaybe<Array<Scalars['String']>>;
  tagsSuperPowers?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<ExpertBlindTypeEnum>;
};

export type UpdateBlindInputType = {
  availability?: InputMaybe<Scalars['String']>;
  recommendation?: InputMaybe<Scalars['String']>;
  englishRecommendation?: InputMaybe<Scalars['String']>;
  margin?: InputMaybe<Scalars['Float']>;
  isMoneyRateDisplayed?: InputMaybe<Scalars['Boolean']>;
  isRecommendationDisplayed?: InputMaybe<Scalars['Boolean']>;
  isWorkHistoryDisplayed?: InputMaybe<Scalars['Boolean']>;
  isCertificatesDisplayed?: InputMaybe<Scalars['Boolean']>;
  isBlindMinus?: InputMaybe<Scalars['Boolean']>;
  isBlindPlus?: InputMaybe<Scalars['Boolean']>;
  isDatepickerHidden?: InputMaybe<Scalars['Boolean']>;
  isVendorless?: InputMaybe<Scalars['Boolean']>;
  isDisplayOnlyFirstLetterOfLastNameChosen?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isFullProfile?: InputMaybe<Scalars['Boolean']>;
  isMoneyRateHidden?: InputMaybe<Scalars['Boolean']>;
  isLastNameHidden?: InputMaybe<Scalars['Boolean']>;
  areCompanyNamesHidden?: InputMaybe<Scalars['Boolean']>;
  isGradeDisabled?: InputMaybe<Scalars['Boolean']>;
  contactPersonId?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  tagsVisibleOnBlind?: InputMaybe<Array<Scalars['String']>>;
  tagsSuperPowers?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<ExpertBlindTypeEnum>;
  verifiedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateFlowStatusInputType = {
  blindId: Scalars['String'];
  flowStatus: ExpertBlindFlowStatusEnum;
  sentTo?: InputMaybe<Array<Scalars['String']>>;
  changedAt?: InputMaybe<Scalars['DateTime']>;
  meetingDate?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<ExpertBlindFlowStatusFeedbackEnum>;
  meetingUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateExpertInputType = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  jobPositionId?: InputMaybe<Scalars['String']>;
  mainTechnologyIds?: InputMaybe<Array<Scalars['String']>>;
  experienceYears?: InputMaybe<Scalars['Int']>;
  rateMin?: InputMaybe<Scalars['Float']>;
  rateMax?: InputMaybe<Scalars['Float']>;
  rateDesired?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  workType?: InputMaybe<WorkModeEnum>;
  seniority?: InputMaybe<SeniorityLevelEnum>;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  tagsVisibleOnBlind?: InputMaybe<Array<Scalars['String']>>;
  tagsSuperPowers?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<LanguageInputType>>;
  certificates?: InputMaybe<Array<ExpertCertificateInputType>>;
  workHistory?: InputMaybe<Array<ExpertWorkHistoryInputType>>;
  recommendation?: InputMaybe<Scalars['String']>;
  englishRecommendation?: InputMaybe<Scalars['String']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  availability?: InputMaybe<ExpertAvailabilityEnum>;
  noticeLength?: InputMaybe<Scalars['Int']>;
  githubUrl?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  descriptiveLabel?: InputMaybe<Scalars['String']>;
  cvId?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<ExpertRoleInputType>>;
  yearsOfExperienceInIt?: InputMaybe<Scalars['Float']>;
  isFilledWithAI?: InputMaybe<Scalars['Boolean']>;
  isExcludedFromJobOrderMatchedNotification?: InputMaybe<Scalars['Boolean']>;
  education?: InputMaybe<Array<ExpertEducationInputType>>;
};

export type LanguageInputType = {
  languageId: Scalars['String'];
  level: LanguageLevelEnum;
};

export type ExpertRoleInputType = {
  jobPositionId: Scalars['String'];
  yearsInRole: Scalars['Float'];
};

export type ExpertEducationInputType = {
  schoolName: Scalars['String'];
  dateFrom: Scalars['DateTime'];
  dateTo?: InputMaybe<Scalars['DateTime']>;
  fieldOfStudyPL: Scalars['String'];
  fieldOfStudyEN: Scalars['String'];
};

export type UpdateJobOrderInputType = {
  jobOrderId?: InputMaybe<Scalars['String']>;
  customId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  clientBudget?: InputMaybe<Scalars['String']>;
  isRelocationRequired?: InputMaybe<Scalars['Boolean']>;
  dateStart1?: InputMaybe<Scalars['DateTime']>;
  dateStart2?: InputMaybe<Scalars['DateTime']>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  owner?: InputMaybe<Scalars['String']>;
  assignees?: InputMaybe<Array<UpdateJobOrderAssigneesParams>>;
  leadingTechnology?: InputMaybe<Scalars['String']>;
  bttl?: InputMaybe<Scalars['Int']>;
  vacancies?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  requirements?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  potentialClientId?: InputMaybe<Scalars['String']>;
  potentialClientName?: InputMaybe<Scalars['String']>;
  responsibilities?: InputMaybe<Scalars['String']>;
  deliveryInformation?: InputMaybe<Scalars['String']>;
  isRemotePossible?: InputMaybe<Scalars['Boolean']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  additionalInfo?: InputMaybe<Scalars['String']>;
  targetProjectInfo?: InputMaybe<Scalars['String']>;
  salesmanComment?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
  interviewsCount?: InputMaybe<Scalars['Int']>;
  industries?: InputMaybe<Array<Scalars['String']>>;
  hiredCount?: InputMaybe<Scalars['Int']>;
  itsMaxSalary?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdByEmail?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  statusCode?: InputMaybe<Scalars['Int']>;
  statusName?: InputMaybe<Scalars['String']>;
  businessStatus?: InputMaybe<JobOrderBusinessStatusEnum>;
  definitionOfReadyStatus?: InputMaybe<JobOrderDefinitionOfReadyStatusEnum>;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  role?: InputMaybe<Scalars['String']>;
  seniority?: InputMaybe<SeniorityLevelEnum>;
  experienceYears?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  jobOrderDescription?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  skillsMustHave?: InputMaybe<Array<ExpertTagWeightInputType>>;
  skillsNiceToHave?: InputMaybe<Array<ExpertTagWeightInputType>>;
  targetProductInfo?: InputMaybe<Scalars['String']>;
  workMethodology?: InputMaybe<WorkMethodologyEnum>;
  isEnglish?: InputMaybe<Scalars['Boolean']>;
  /** to be refined */
  minEnglishLevel?: InputMaybe<LanguageLevelEnum>;
  workMode?: InputMaybe<WorkModeEnum>;
  workModeParamsDescription?: InputMaybe<Scalars['String']>;
  locationPreference?: InputMaybe<Scalars['String']>;
  officeLocation?: InputMaybe<Scalars['String']>;
  officeLocationObject?: InputMaybe<GoogleLocationObjectInput>;
  rateRangeFrom?: InputMaybe<Scalars['Int']>;
  rateRangeTo?: InputMaybe<Scalars['Int']>;
  minMargin?: InputMaybe<Scalars['Float']>;
  workOrderLengthInMonths?: InputMaybe<Scalars['Int']>;
  workOrderLength?: InputMaybe<WorkOrderLengthEnum>;
  /** For how long the work order is open */
  maxAvailability?: InputMaybe<MaxAvailabilityEnum>;
  workHoursScope?: InputMaybe<WorkHourScopeEnum>;
  clientParamsScore?: InputMaybe<UpdateJobOrderClientParamsScore>;
  /** Additional custom flag (not mapped from CRM) to mark that JO has all required information */
  allRequiredFieldsAreFilled?: InputMaybe<Scalars['Boolean']>;
  statusHistory?: InputMaybe<Array<JobOrderStatusHistoryInputType>>;
  assignmentHistory?: InputMaybe<Array<JobOrderAssignmentHistoryInputType>>;
  isChubVisible?: InputMaybe<Scalars['Boolean']>;
  fillRateKpi?: InputMaybe<Scalars['Boolean']>;
  fillRateKpiComment?: InputMaybe<FillRateKpiCommentEnum>;
  businessOwner?: InputMaybe<Array<JobOrderBusinessOwnerInputType>>;
  hubSpotCompanyId?: InputMaybe<Scalars['String']>;
  hubSpotCompanyName?: InputMaybe<Scalars['String']>;
  resultStatus?: InputMaybe<JobOrderResultEnum>;
  type?: InputMaybe<JobOrderTypeEnum>;
  isPTCBusinessDeadlineEnabled?: InputMaybe<Scalars['Boolean']>;
  PTCBusinessDeadline?: InputMaybe<Scalars['DateTime']>;
  summarySentAt?: InputMaybe<Array<Scalars['DateTime']>>;
  isJobPositionStrictlyRequired?: InputMaybe<Scalars['Boolean']>;
  isFilledWithAI?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateJobOrderAssigneesParams = {
  brm?: InputMaybe<Scalars['String']>;
  talentAdvocates?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateJobOrderClientParamsScore = {
  roleLeadingTechnologyScore?: InputMaybe<DifficultyLevelEnum>;
  rateScore?: InputMaybe<DifficultyLevelEnum>;
  businessDescriptionScore?: InputMaybe<QualityLevelEnum>;
  jobOrderDescriptionScore?: InputMaybe<QualityLevelEnum>;
  businessRequirementsDescriptionScore?: InputMaybe<QualityLevelEnum>;
  businessResponsibilityDescriptionScore?: InputMaybe<QualityLevelEnum>;
  clientPtcFeedbackScore?: InputMaybe<FeedbackScoreEnum>;
  clientCiFeedbackScore?: InputMaybe<FeedbackScoreEnum>;
  clientRecruitmentProcessLength?: InputMaybe<RecruitmentProcessLengthEnum>;
  /** how many profiles should be sent before JO can be considered "done" */
  fillRateThresholdPerOpening?: InputMaybe<Scalars['Int']>;
  recruitmentTest?: InputMaybe<RecruitmentTestEnum>;
  isVerificationNeeded?: InputMaybe<Scalars['Boolean']>;
  contractSigned?: InputMaybe<ContractSignedEnum>;
  jobOrderScore?: InputMaybe<Scalars['Float']>;
};

export type SendJobOrderSummaryEmailInputType = {
  to: Array<Scalars['String']>;
  subject: Scalars['String'];
  message: Scalars['String'];
  accessToken: Scalars['String'];
};

export type UpdateJobOrderAssignmentInputType = {
  owner?: InputMaybe<Scalars['String']>;
  assignees?: InputMaybe<Array<UpdateJobOrderAssigneesParams>>;
  shouldResetBttl?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateJobOrderBusinessStatusInputType = {
  jobOrderId: Scalars['String'];
  businessStatus: JobOrderBusinessStatusEnum;
  resultStatus?: InputMaybe<JobOrderResultEnum>;
};

export type InsertLanguageInputType = {
  name: Scalars['String'];
  nameEn: Scalars['String'];
};

export type UpdateTagInputType = {
  name?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TagTypeEnum>;
};

export type UpdateTagsInputType = {
  name?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TagTypeEnum>;
  id: Scalars['String'];
};

export type UpdateExpertProfileInputType = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  jobPositionId?: InputMaybe<Scalars['String']>;
  mainTechnologyIds?: InputMaybe<Array<Scalars['String']>>;
  experienceYears?: InputMaybe<Scalars['Int']>;
  rateMin?: InputMaybe<Scalars['Float']>;
  rateMax?: InputMaybe<Scalars['Float']>;
  rateDesired?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationObject?: InputMaybe<GoogleLocationObjectInput>;
  desiredOfficeLocations?: InputMaybe<Array<Scalars['String']>>;
  desiredTechnicalSkills?: InputMaybe<Array<Scalars['String']>>;
  desiredIndustries?: InputMaybe<Array<Scalars['String']>>;
  desiredFields?: InputMaybe<Array<Scalars['String']>>;
  workType?: InputMaybe<WorkModeEnum>;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  tagsSuperPowers?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<LanguageInputType>>;
  seniority?: InputMaybe<SeniorityLevelEnum>;
  certificates?: InputMaybe<Array<ExpertCertificateInputType>>;
  workHistory?: InputMaybe<Array<ExpertWorkHistoryInputType>>;
  availability?: InputMaybe<Scalars['String']>;
  noticeLength?: InputMaybe<Scalars['Int']>;
  manuallyAddedJobPositions?: InputMaybe<Array<Scalars['String']>>;
  manuallyAddedMainTechnologies?: InputMaybe<Array<Scalars['String']>>;
  manuallyAddedDesiredTechnicalSkills?: InputMaybe<Array<Scalars['String']>>;
  avatar?: InputMaybe<ExpertAvatarEnum>;
  isVerifiedNotificationDisplayed?: InputMaybe<Scalars['Boolean']>;
  githubUrl?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  isCertificatesSkipped?: InputMaybe<Scalars['Boolean']>;
  cvId?: InputMaybe<Scalars['String']>;
};

export type UploadExpertAvatarInputType = {
  fileBase64: Scalars['String'];
  mimeType: Scalars['String'];
};

export type UploadExpertWordCloudInputType = {
  fileBase64: Scalars['String'];
};

export type CreateExpertBlindViewHistoryInput = {
  timestamp?: InputMaybe<Scalars['DateTime']>;
  userAgent: Scalars['String'];
  blindId: Scalars['String'];
  ip: Scalars['String'];
};

export type InsertNewsInputType = {
  title: Scalars['String'];
  content: Scalars['String'];
  description: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateNewsInputType = {
  title?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateJobOrderApplicationInputType = {
  meetingDates?: InputMaybe<Array<Scalars['String']>>;
  handlerId?: InputMaybe<Scalars['String']>;
  handlingExpiresAt?: InputMaybe<Scalars['String']>;
  handlingConfirmedAt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<JobOrderApplicationStatusEnum>;
  selectedMeetingDate?: InputMaybe<Scalars['String']>;
  processStatus?: InputMaybe<JobOrderApplicationProcessStatusEnum>;
  meetingUrl?: InputMaybe<Scalars['String']>;
};

export enum QueueTypeEnum {
  AllCompaniesReportRequested = 'AllCompaniesReportRequested',
  AdminBlindsReportRequested = 'AdminBlindsReportRequested',
  ExpertBlindCreated = 'ExpertBlindCreated',
  ExpertBlindDeleted = 'ExpertBlindDeleted',
  ExpertBlindGradeUpdated = 'ExpertBlindGradeUpdated',
  ExpertBlindPlacement = 'ExpertBlindPlacement',
  ExpertBlindRefreshRequested = 'ExpertBlindRefreshRequested',
  ExpertBlindUpdated = 'ExpertBlindUpdated',
  ExpertBlindFlowStatusUpdated = 'ExpertBlindFlowStatusUpdated',
  ExpertBlindStatisticsUpdate = 'ExpertBlindStatisticsUpdate',
  ExpertCrmTextValuesUpdated = 'ExpertCrmTextValuesUpdated',
  ExpertNotificationInserted = 'ExpertNotificationInserted',
  ExpertSearchLog = 'ExpertSearchLog',
  ExpertUpdated = 'ExpertUpdated',
  JobOrderApplicationInserted = 'JobOrderApplicationInserted',
  JobOrderApplicationUpdated = 'JobOrderApplicationUpdated',
  JobOrderDefinitionOfReadyStatusAccepted = 'JobOrderDefinitionOfReadyStatusAccepted',
  JobOrderInserted = 'JobOrderInserted',
  JobOrderMatchedToExpert = 'JobOrderMatchedToExpert',
  JobOrderUpdated = 'JobOrderUpdated',
  JobOrderVisibilityChanged = 'JobOrderVisibilityChanged',
  UserRequest = 'UserRequest',
  JobOrderApplicationCiScheduled = 'JobOrderApplicationCiScheduled',
  JobOrderApplicationExpired = 'JobOrderApplicationExpired',
  JobOrderFillRateUpdated = 'JobOrderFillRateUpdated',
  PublicOfferApplicationCreated = 'PublicOfferApplicationCreated',
  PublicOfferApplicationAutoExtractionRequested = 'PublicOfferApplicationAutoExtractionRequested',
  PublicOfferViewed = 'PublicOfferViewed',
  BlindStatusChangedFromClientDashboardNotification = 'BlindStatusChangedFromClientDashboardNotification',
}

export type CreatePublicOfferInputType = {
  jobOrderId: Scalars['String'];
  ownerId: Scalars['String'];
  description: Scalars['String'];
  isRateDisplayed: Scalars['Boolean'];
  context: PublicOfferContextEnum;
  contextContactEmail?: InputMaybe<Scalars['String']>;
};

export type UpdatePublicOfferInputType = {
  ownerId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isRateDisplayed?: InputMaybe<Scalars['Boolean']>;
  context?: InputMaybe<PublicOfferContextEnum>;
  contextContactEmail?: InputMaybe<Scalars['String']>;
};

export type ApplyForPublicOfferInputType = {
  offerId: Scalars['String'];
  cvFileId: Scalars['String'];
  expertId: Scalars['String'];
  recaptchaToken: Scalars['String'];
  recaptchaAction: Scalars['String'];
};

export type SendPublicOfferEmailInputType = {
  offerId: Scalars['String'];
  accessToken: Scalars['String'];
  subject: Scalars['String'];
  message: Scalars['String'];
  attachments: Array<SendPublicOfferAttachmentInputType>;
};

export type SendPublicOfferAttachmentInputType = {
  name: Scalars['String'];
  mimeType: Scalars['String'];
  contentBase64: Scalars['String'];
};

export type UpdateJobOrderPublicationsInputType = {
  mediumId: Scalars['String'];
  url: Scalars['String'];
};

export type SignExpertCvUploadCredentialsInputType = {
  name: Scalars['String'];
  size: Scalars['Int'];
  mimeType: Scalars['String'];
  source: ExpertCvSourceEnum;
  offerId?: InputMaybe<Scalars['String']>;
  expertId?: InputMaybe<Scalars['String']>;
  expertEmail?: InputMaybe<Scalars['String']>;
};

export type SignExpertCvUploadCredentialsWithRecaptchaInputType = {
  name: Scalars['String'];
  size: Scalars['Int'];
  mimeType: Scalars['String'];
  source: ExpertCvSourceEnum;
  offerId?: InputMaybe<Scalars['String']>;
  expertId?: InputMaybe<Scalars['String']>;
  expertEmail?: InputMaybe<Scalars['String']>;
  recaptchaToken: Scalars['String'];
  recaptchaAction: Scalars['String'];
};

export type StartExpertCvExtractionInputType = {
  cvId: Scalars['String'];
  autoAnonymizationAcceptanceEnabled?: InputMaybe<Scalars['Boolean']>;
  autoExtractionAcceptanceEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type SignExpertBlindAttachmentUploadCredentialsInputType = {
  name: Scalars['String'];
  size: Scalars['Int'];
  mimeType: Scalars['String'];
  blindId: Scalars['String'];
};
