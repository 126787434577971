import * as Types from '../../../interfaces/api.types.generated.d';

import { gql } from '@apollo/client';
export type JobOrderApplicationFragmentFragment = {
  __typename?: 'JobOrderApplicationType';
  applicationId: string;
  createdAt: string;
  meetingDates: Array<string>;
  selectedMeetingDate?: string | null;
  status: Types.JobOrderApplicationStatusEnum;
  processStatus: Types.JobOrderApplicationProcessStatusEnum;
  meetingUrl?: string | null;
  isViewedByExpert: boolean;
  isHubApplicationDisabled: boolean;
  handler?: { __typename?: 'UserType'; name: string; email: string } | null;
};

export const JobOrderApplicationFragmentFragmentDoc = gql`
  fragment JobOrderApplicationFragment on JobOrderApplicationType {
    applicationId
    createdAt
    meetingDates
    selectedMeetingDate
    status
    processStatus
    meetingUrl
    isViewedByExpert
    handler {
      name
      email
    }
    isHubApplicationDisabled
  }
`;
