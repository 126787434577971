import { Modal, ModalProps } from 'components';
import { useSitemap } from 'hooks';
import { JobOrderApplicationProcessStatusEnum, JobOrderQueryType } from 'interfaces';
import { FC, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mergeClasses } from 'utils';
import { EnvironmentConfig } from '../../../environment-config';
import {
  JobOrderDetailsContactPerson,
  JobOrderDetailsDescription,
  JobOrderDetailsHeader,
  JobOrderDetailsRate,
  JobOrderDetailsRecommendation,
  JobOrderDetailsTechnologies,
} from './subcomponents';
import { ExpiredOfferInfo } from './subcomponents/ExpiredOfferInfo';
import JobOrdersMatchingDetails from './subcomponents/JobOrdersMatchingDetails';

interface JobOrderDetailsModalProps extends ModalProps {
  jobOrder: JobOrderQueryType | null;
  onScheduleMeetingClick?: (jobOrderId: string) => void;
  onFavouriteClick?: (jobOrderId: string) => void;
  isMeetingScheduled: boolean;
}

export const JobOrderDetailsModal: FC<JobOrderDetailsModalProps> = ({
  jobOrder,
  onScheduleMeetingClick,
  onFavouriteClick,
  isMeetingScheduled,
  ...modalProps
}) => {
  const navigate = useNavigate();
  const sitemap = useSitemap();

  const [isMatchingDetailsScoreCalculationOpen, setIsMatchingDetailsScoreCalculationOpen] =
    useState(false);
  const [areTechnicalDetailsOpen, setAreTechnicalDetailsOpen] = useState(false);

  useLayoutEffect(() => {
    if (!jobOrder) {
      return;
    }
    const shouldBeExpanded = jobOrder.score.finalScore < EnvironmentConfig.minimalScoreToApply;
    setIsMatchingDetailsScoreCalculationOpen(shouldBeExpanded);
  }, [jobOrder]);

  if (!jobOrder) {
    return null;
  }

  const handleShowMatchingDetailsScoreCalculation = () => {
    setIsMatchingDetailsScoreCalculationOpen((prev) => !prev);
  };

  const handleModalClose = () => {
    if (modalProps.onClose) {
      setAreTechnicalDetailsOpen(false);
      setIsMatchingDetailsScoreCalculationOpen(false);
      modalProps.onClose();
    }
  };

  const handleSeeMoreOffers = () => {
    if (modalProps.onClose) {
      navigate(sitemap.offers('all'));
      modalProps.onClose();
    }
  };

  const isApplied = !!jobOrder.application;
  const isOfferExpired = !jobOrder.isChubVisible && !isApplied;
  const isApplicationExpired =
    jobOrder.application?.processStatus === JobOrderApplicationProcessStatusEnum.OfferExpired;
  const isApplyButtonDisabled =
    jobOrder.score.finalScore < EnvironmentConfig.minimalScoreToApply ||
    jobOrder.application?.isHubApplicationDisabled;
  const shouldDisplayRecommendation =
    isApplicationExpired || ((isOfferExpired || isApplyButtonDisabled) && !isApplied);

  return (
    <Modal
      className={mergeClasses(
        'rounded-lg',
        isOfferExpired
          ? 'w-full md:w-11/12 max-w-[1410px] h-auto py-8 md:pt-[87px] md:pb-[126px] px-4 flex flex-col items-center content-center'
          : 'w-full md:w-11/12 max-w-[1410px] pt-6 lg:pt-12 px-4 md:p-10 lg:max-h-[calc(100vh-100px)]',
      )}
      {...modalProps}
      backgroundClassName="px-4 !md:p-4 bottom-0 lg:py-[50px]"
      iconButtonClassName="top-8 right-4 md:right-8 p-0"
      iconClassName="w-8 h-8"
      onClose={handleModalClose}
    >
      {isOfferExpired ? (
        <ExpiredOfferInfo onSeeMoreOffers={handleSeeMoreOffers} />
      ) : (
        <>
          <JobOrderDetailsHeader jobOrder={jobOrder} />
          <div className="flex items-start gap-x-10 flex-col-reverse lg:flex-row">
            <div className="w-full">
              <JobOrdersMatchingDetails
                isMatchingDetailsScoreCalculationVisible={isMatchingDetailsScoreCalculationOpen}
                jobOrder={jobOrder}
                onShowMatchingDetailsScoreCalculation={handleShowMatchingDetailsScoreCalculation}
              />
              <JobOrderDetailsTechnologies
                areTechnicalDetailsVisible={areTechnicalDetailsOpen}
                jobOrder={jobOrder}
                setAreTechnicalDetailsOpen={setAreTechnicalDetailsOpen}
              />
              <JobOrderDetailsDescription jobOrder={jobOrder} />
              {shouldDisplayRecommendation && (
                <div className="block lg:hidden">
                  <JobOrderDetailsRecommendation closeModal={handleModalClose} />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-6 !w-full lg:!w-auto lg:mt-8">
              <JobOrderDetailsRate
                isApplyButtonDisabled={isApplyButtonDisabled}
                isMeetingScheduled={isMeetingScheduled}
                jobOrder={jobOrder}
                onFavouriteClick={onFavouriteClick}
                onScheduleMeetingClick={onScheduleMeetingClick}
              />
              {shouldDisplayRecommendation && (
                <div className="hidden lg:block">
                  <JobOrderDetailsRecommendation closeModal={handleModalClose} />
                </div>
              )}
              <JobOrderDetailsContactPerson jobOrder={jobOrder} />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
