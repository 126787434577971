import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
import { JobOrderFragmentFragmentDoc } from '../fragments/jobOrder.fragment.generated';
const defaultOptions = {} as const;
export type JobOrdersUnderMinScoreToApplyQueryVariables = Types.Exact<{
  params: Types.OtherJobOrdersQueryInputType;
}>;

export type JobOrdersUnderMinScoreToApplyQuery = {
  __typename?: 'Query';
  jobOrdersUnderMinScoreToApply: Array<{
    __typename?: 'JobOrderWithScoreType';
    jobOrderId: string;
    customId: number;
    role?: string | null;
    clientName?: string | null;
    seniority?: Types.SeniorityLevelEnum | null;
    experienceYears?: number | null;
    description?: string | null;
    dateStart1?: string | null;
    dateStart2?: string | null;
    dateEnd?: string | null;
    locationPreference?: string | null;
    minEnglishLevel?: Types.LanguageLevelEnum | null;
    workMode?: Types.WorkModeEnum | null;
    rateRangeTo?: number | null;
    rateRangeFrom?: number | null;
    isEnglish?: boolean | null;
    maxAvailability?: Types.MaxAvailabilityEnum | null;
    createdAt: string;
    assignmentDate?: string | null;
    isSaved: boolean;
    isChubVisible: boolean;
    leadingTechnology?: string | null;
    leadingTechnologyName?: string | null;
    isJobPositionStrictlyRequired: boolean;
    skillsMustHave: Array<{
      __typename?: 'ExpertTagType';
      id: string;
      name: string;
      weight: number;
    }>;
    skillsNiceToHave: Array<{
      __typename?: 'ExpertTagType';
      id: string;
      name: string;
      weight: number;
    }>;
    owner?: { __typename?: 'UserType'; email: string; name: string } | null;
    assignees?: Array<{
      __typename?: 'JobOrderAssigneesParamsType';
      brm?: string | null;
      talentAdvocates?: Array<string> | null;
    }> | null;
    score: {
      __typename?: 'CalculateScoreResultType';
      finalScore: number;
      mustHaveSkillsScore: number;
      niceToHaveSkillsScore: number;
      salaryScore: number;
      workModeScore: number;
      englishLevelScore: number;
      availabilityScore: number;
      weightingStrategy: Types.MatchingScoreWeightingStrategyEnum;
      roleScore: number;
      yearsInRequiredRole: number;
      expertDetails?: {
        __typename?: 'CalculateScoreResultExpertDetailsType';
        niceToHaveSkills: Array<string>;
        englishLanguageLevel?: Types.LanguageLevelEnum | null;
        workMode?: Types.WorkModeEnum | null;
        availability?: Types.ExpertAvailabilityEnum | null;
        noticePeriod?: number | null;
        rateDesired?: number | null;
        mustHaveSkillsLevels: Array<{
          __typename?: 'MustHaveSkillLevel';
          id: string;
          level: number;
        }>;
        roles: Array<{ __typename?: 'ExpertRoleType'; jobPositionId: string; yearsInRole: number }>;
      } | null;
    };
    application?: {
      __typename?: 'JobOrderApplicationType';
      applicationId: string;
      createdAt: string;
      meetingDates: Array<string>;
      selectedMeetingDate?: string | null;
      status: Types.JobOrderApplicationStatusEnum;
      processStatus: Types.JobOrderApplicationProcessStatusEnum;
      meetingUrl?: string | null;
      isViewedByExpert: boolean;
      isHubApplicationDisabled: boolean;
      handler?: { __typename?: 'UserType'; name: string; email: string } | null;
    } | null;
  }>;
};

export const JobOrdersUnderMinScoreToApplyDocument = gql`
  query jobOrdersUnderMinScoreToApply($params: OtherJobOrdersQueryInputType!) {
    jobOrdersUnderMinScoreToApply(params: $params) {
      ...JobOrderFragment
    }
  }
  ${JobOrderFragmentFragmentDoc}
`;

/**
 * __useJobOrdersUnderMinScoreToApplyQuery__
 *
 * To run a query within a React component, call `useJobOrdersUnderMinScoreToApplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobOrdersUnderMinScoreToApplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobOrdersUnderMinScoreToApplyQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useJobOrdersUnderMinScoreToApplyQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobOrdersUnderMinScoreToApplyQuery,
    JobOrdersUnderMinScoreToApplyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    JobOrdersUnderMinScoreToApplyQuery,
    JobOrdersUnderMinScoreToApplyQueryVariables
  >(JobOrdersUnderMinScoreToApplyDocument, options);
}
export function useJobOrdersUnderMinScoreToApplyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobOrdersUnderMinScoreToApplyQuery,
    JobOrdersUnderMinScoreToApplyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    JobOrdersUnderMinScoreToApplyQuery,
    JobOrdersUnderMinScoreToApplyQueryVariables
  >(JobOrdersUnderMinScoreToApplyDocument, options);
}
export type JobOrdersUnderMinScoreToApplyQueryHookResult = ReturnType<
  typeof useJobOrdersUnderMinScoreToApplyQuery
>;
export type JobOrdersUnderMinScoreToApplyLazyQueryHookResult = ReturnType<
  typeof useJobOrdersUnderMinScoreToApplyLazyQuery
>;
export type JobOrdersUnderMinScoreToApplyQueryResult = Apollo.QueryResult<
  JobOrdersUnderMinScoreToApplyQuery,
  JobOrdersUnderMinScoreToApplyQueryVariables
>;
export function refetchJobOrdersUnderMinScoreToApplyQuery(
  variables: JobOrdersUnderMinScoreToApplyQueryVariables,
) {
  return { query: JobOrdersUnderMinScoreToApplyDocument, variables: variables };
}
