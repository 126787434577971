import { DiscordIcon, FacebookIcon, InstagramIcon, LinkedInJobBoardIcon } from 'icons';

export const socials = [
  {
    name: 'linkedIn',
    icon: LinkedInJobBoardIcon,
    url: 'https://www.linkedin.com/company/connectispl/',
  },
  {
    name: 'facebook',
    icon: FacebookIcon,
    url: 'https://www.facebook.com/ConnectisPL',
  },
  {
    name: 'instagram',
    icon: InstagramIcon,
    url: 'https://www.instagram.com/connectis_/',
  },
  {
    name: 'discord',
    icon: DiscordIcon,
    url: 'https://discord.com/invite/JEWYRuS2KG',
  },
];
