import * as Types from '../../../interfaces/api.types.generated.d';

import { gql } from '@apollo/client';
import { ExpertDetailsFragmentFragmentDoc } from './expertDetails.fragment.generated';
import { JobOrderApplicationFragmentFragmentDoc } from './jobOrderApplication.fragment.generated';
export type JobOrderFragmentFragment = {
  __typename?: 'JobOrderWithScoreType';
  jobOrderId: string;
  customId: number;
  role?: string | null;
  clientName?: string | null;
  seniority?: Types.SeniorityLevelEnum | null;
  experienceYears?: number | null;
  description?: string | null;
  dateStart1?: string | null;
  dateStart2?: string | null;
  dateEnd?: string | null;
  locationPreference?: string | null;
  minEnglishLevel?: Types.LanguageLevelEnum | null;
  workMode?: Types.WorkModeEnum | null;
  rateRangeTo?: number | null;
  rateRangeFrom?: number | null;
  isEnglish?: boolean | null;
  maxAvailability?: Types.MaxAvailabilityEnum | null;
  createdAt: string;
  assignmentDate?: string | null;
  isSaved: boolean;
  isChubVisible: boolean;
  leadingTechnology?: string | null;
  leadingTechnologyName?: string | null;
  isJobPositionStrictlyRequired: boolean;
  skillsMustHave: Array<{ __typename?: 'ExpertTagType'; id: string; name: string; weight: number }>;
  skillsNiceToHave: Array<{
    __typename?: 'ExpertTagType';
    id: string;
    name: string;
    weight: number;
  }>;
  owner?: { __typename?: 'UserType'; email: string; name: string } | null;
  assignees?: Array<{
    __typename?: 'JobOrderAssigneesParamsType';
    brm?: string | null;
    talentAdvocates?: Array<string> | null;
  }> | null;
  score: {
    __typename?: 'CalculateScoreResultType';
    finalScore: number;
    mustHaveSkillsScore: number;
    niceToHaveSkillsScore: number;
    salaryScore: number;
    workModeScore: number;
    englishLevelScore: number;
    availabilityScore: number;
    weightingStrategy: Types.MatchingScoreWeightingStrategyEnum;
    roleScore: number;
    yearsInRequiredRole: number;
    expertDetails?: {
      __typename?: 'CalculateScoreResultExpertDetailsType';
      niceToHaveSkills: Array<string>;
      englishLanguageLevel?: Types.LanguageLevelEnum | null;
      workMode?: Types.WorkModeEnum | null;
      availability?: Types.ExpertAvailabilityEnum | null;
      noticePeriod?: number | null;
      rateDesired?: number | null;
      mustHaveSkillsLevels: Array<{ __typename?: 'MustHaveSkillLevel'; id: string; level: number }>;
      roles: Array<{ __typename?: 'ExpertRoleType'; jobPositionId: string; yearsInRole: number }>;
    } | null;
  };
  application?: {
    __typename?: 'JobOrderApplicationType';
    applicationId: string;
    createdAt: string;
    meetingDates: Array<string>;
    selectedMeetingDate?: string | null;
    status: Types.JobOrderApplicationStatusEnum;
    processStatus: Types.JobOrderApplicationProcessStatusEnum;
    meetingUrl?: string | null;
    isViewedByExpert: boolean;
    isHubApplicationDisabled: boolean;
    handler?: { __typename?: 'UserType'; name: string; email: string } | null;
  } | null;
};

export const JobOrderFragmentFragmentDoc = gql`
  fragment JobOrderFragment on JobOrderWithScoreType {
    jobOrderId
    customId
    role
    clientName
    skillsMustHave {
      id
      name
      weight
    }
    skillsNiceToHave {
      id
      name
      weight
    }
    seniority
    experienceYears
    description
    dateStart1
    dateStart2
    dateEnd
    locationPreference
    minEnglishLevel
    workMode
    rateRangeTo
    rateRangeFrom
    isEnglish
    minEnglishLevel
    maxAvailability
    owner {
      email
      name
    }
    assignees {
      brm
      talentAdvocates
    }
    score {
      finalScore
      mustHaveSkillsScore
      niceToHaveSkillsScore
      salaryScore
      workModeScore
      englishLevelScore
      availabilityScore
      weightingStrategy
      roleScore
      yearsInRequiredRole
      expertDetails {
        ...ExpertDetailsFragment
      }
    }
    createdAt
    assignmentDate
    dateEnd
    isSaved
    isChubVisible
    application {
      ...JobOrderApplicationFragment
    }
    leadingTechnology
    leadingTechnologyName
    isJobPositionStrictlyRequired
  }
  ${ExpertDetailsFragmentFragmentDoc}
  ${JobOrderApplicationFragmentFragmentDoc}
`;
