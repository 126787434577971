import { useReactiveVar } from '@apollo/client';
import { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { CognitoError } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isAuthenticatedVar, profileVar } from 'reactive-vars';
import { EnvironmentConfig } from '../environment-config';
import { useCreateExpertMutation } from './api/createExpert/createExpert.generated';
import { useDeleteExpertAccountMutation } from './api/deleteExpertAccount/deleteExpertAccount.generated';
import { useAsyncCallback } from './useAsyncCallback';
import { useSitemap } from './useSitemap';

interface CognitoUserDto {
  email: string;
  password: string;
}

const getCognitoAuthenticatedUser = () => Auth.currentAuthenticatedUser();
const getIsFederatedSignIn = async () => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user || !user?.attributes?.identities) {
    return false;
  }
  return true;
};

const transformMail = (email: string) => email.trim().toLowerCase();

export const getAuthorizationToken = async () => {
  try {
    const cognitoAuthenticatedUser: CognitoUser = await getCognitoAuthenticatedUser();
    const userSession = cognitoAuthenticatedUser.getSignInUserSession();
    if (!userSession) {
      throw new Error('Empty session');
    }
    return userSession.getIdToken().getJwtToken();
  } catch (err) {
    return undefined;
  }
};

export const useAuth = () => {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);
  const { t } = useTranslation();

  const [createExpert, { client }] = useCreateExpertMutation();
  const [deleteExpertAccount] = useDeleteExpertAccountMutation({
    errorPolicy: 'ignore',
  });

  const navigate = useNavigate();
  const sitemap = useSitemap();

  const [verifyAuthentication, { loading: verifyLoading }] = useAsyncCallback(
    async () => {
      const authenticatedUser = await getCognitoAuthenticatedUser();
      isAuthenticatedVar(!!authenticatedUser);
      if (!authenticatedUser) {
        profileVar(null);
      }
      if (authenticatedUser) {
        const isFederatedSignIn = await getIsFederatedSignIn();
        if (isFederatedSignIn) {
          await createExpert().catch(console.error);
        }
      }
      return authenticatedUser;
    },
    {
      onError: () => isAuthenticatedVar(false),
    },
  );

  const [signInToCognito, { loading: signInLoading }] = useAsyncCallback(async (email: string) => {
    const initiateAuthResult = await Auth.signIn(transformMail(email), undefined);

    if (initiateAuthResult.challengeParam.flow === 'EMAIL_OTP') {
      return {
        flowType: 'EMAIL_OTP',
        initiateAuthResult,
      };
    }
    if (initiateAuthResult.challengeParam.flow === 'USER_PASSWORD') {
      return {
        flowType: 'USER_PASSWORD',
        initiateAuthResult,
      };
    }

    throw new Error('Unhandled auth flow');
  });

  const [signInToCognitoWithPassword, { loading: signInWithPasswordLoading }] = useAsyncCallback(
    async ({ email, password }: CognitoUserDto): Promise<any> => {
      const result = await Auth.signIn(email, password);
      await createExpert().catch(console.error);
      verifyAuthentication();
      toast.success(t('toasts:signInForm.signInSuccess'));
      return result as CognitoUser;
    },
  );

  const [signInToCognitoWithOtp, { loading: signInWithOtpLoading }] = useAsyncCallback(
    async ({
      initiateAuthResult,
      otp,
      shouldVerifyAuthentication = true,
    }: {
      initiateAuthResult: any;
      shouldVerifyAuthentication: boolean;
      otp: string;
    }): Promise<any | CognitoError> => {
      const result = await Auth.sendCustomChallengeAnswer(initiateAuthResult, otp);
      await createExpert().catch(console.error);
      if (shouldVerifyAuthentication) {
        await verifyAuthentication();
      }
      return result as CognitoUser;
    },
    {
      rethrow: true,
    },
  );

  const [signOutFromCognito, { loading: signOutLoading }] = useAsyncCallback(async () => {
    await Auth.signOut();
    await verifyAuthentication();
    await client.clearStore();
    await profileVar(null);
    navigate(sitemap.main);
  });

  const [signInWithGoogle, { loading: googleSignInLoading }] = useAsyncCallback(async () =>
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    }),
  );

  const [signInWithLinkedIn, { loading: linkedInSignInLoading }] = useAsyncCallback(async () => {
    const result = await Auth.federatedSignIn({
      customProvider: EnvironmentConfig.cognito.linkedInCustomProviderName,
    });

    return result;
  });

  const [resetCognitoPassword, { loading: resetCognitoPasswordLoading }] = useAsyncCallback(
    async (params: { email: string }) => {
      const { email } = params;
      await Auth.forgotPassword(transformMail(email));
    },
  );

  const [setNewCognitoPassword, { loading: setNewCognitoPasswordLoading }] = useAsyncCallback(
    async (params: {
      email: string;
      code: string;
      password: string;
    }): Promise<CognitoError | void> => {
      const { email, code, password } = params;
      await Auth.forgotPasswordSubmit(transformMail(email), code, password);
    },
  );

  const [changeUserPassword, { loading: changeUserPasswordLoading }] = useAsyncCallback(
    async (params: {
      currentPassword: string;
      newPassword: string;
    }): Promise<CognitoError | void> => {
      const authenticatedUser = await getCognitoAuthenticatedUser();
      const { currentPassword, newPassword } = params;
      await Auth.changePassword(authenticatedUser, currentPassword, newPassword);
    },
  );

  const [deleteUser, { loading: deleteUserLoading }] = useAsyncCallback(
    async (params: { email: string; password?: string }): Promise<any> => {
      const { email, password } = params;

      const user = email && password ? await Auth.signIn(email, password) : {};

      if (!user) {
        return;
      }

      await deleteExpertAccount();

      await Auth.deleteUser();

      await signOutFromCognito();

      return 'Success';
    },
  );

  const getCognitoSignInMethod = async (): Promise<'OTP' | 'PASSWORD'> => {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    return user.attributes['custom:password_type'] === 'OTP' ? 'OTP' : 'PASSWORD';
  };

  return {
    signInToCognito,
    signInToCognitoWithPassword,
    signInToCognitoWithOtp,
    signOutFromCognito,
    signInWithGoogle,
    signInWithLinkedIn,
    resetCognitoPassword,
    setNewCognitoPassword,
    changeUserPassword,
    deleteUser,
    verifyAuthentication,
    getCognitoSignInMethod,
    isAuthenticated,
    authLoading:
      signInLoading ||
      signInWithPasswordLoading ||
      signInWithOtpLoading ||
      signOutLoading ||
      verifyLoading ||
      googleSignInLoading ||
      setNewCognitoPasswordLoading ||
      changeUserPasswordLoading ||
      deleteUserLoading ||
      resetCognitoPasswordLoading ||
      linkedInSignInLoading,
  };
};
